<template>
  <div v-show="!showModal">
    <a-spin :spinning="loading">
      <a-form ref="form" :model="formState" name="form" @finish="onSearch">
        <!-- 搜索栏 -->
        <a-row>
          <a-form-item label="一卡通名称" name="title" class="ui-form__item">
            <a-input v-model:value="formState.title" placeholder="请输入一卡通名称"></a-input>
          </a-form-item>

          <a-form-item label="用户信息" name="userInfo" class="ui-form__item">
            <a-input v-model:value="formState.userInfo" placeholder="请输入用户信息" />
          </a-form-item>

          <a-form-item label="一卡通卡号" name="cardNo" class="ui-form__item">
            <a-input v-model:value="formState.cardNo" placeholder="请输入一卡通卡号"></a-input>
          </a-form-item>

          <a-form-item label="获得方式" name="fromType" class="ui-form__item">
            <a-select v-model:value="formState.fromType" style="width: 180px;" allow-clear placeholder="请选择获得方式">
              <a-select-option :value="1">用户自行购买</a-select-option>
              <a-select-option :value="2">转赠</a-select-option>
              <a-select-option :value="3">后台发放</a-select-option>
              <a-select-option :value="4">兑换券码</a-select-option>
			  <a-select-option :value="5">购买商品赠送</a-select-option>
			  <a-select-option :value="6">会员权益兑换</a-select-option>
			  <a-select-option :value="7">抽奖获得</a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item label="卡状态" name="status" class="ui-form__item">
            <a-select v-model:value="formState.status" style="width: 180px;" allow-clear placeholder="请选择卡状态">
              <a-select-option :value="0">待激活</a-select-option>
              <a-select-option :value="1">已激活</a-select-option>
              <a-select-option :value="2">转赠中</a-select-option>
              <a-select-option :value="3">已赠送</a-select-option>
			  <a-select-option :value="4">已退款</a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item label="卡类型" name="cardType" class="ui-form__item">
            <a-select v-model:value="formState.cardType" style="width: 180px;" allow-clear placeholder="请选择卡类型">
              <a-select-option :value="1">普通卡</a-select-option>
              <a-select-option :value="2">主题卡</a-select-option>
              <a-select-option :value="3">礼品卡</a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item label="状态" name="isDisabled" class="ui-form__item">
            <a-select v-model:value="formState.isDisabled" placeholder="请选择状态" allow-clear style="width: 180px;">
              <a-select-option :value="0">启用</a-select-option>
              <a-select-option :value="1">禁用</a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item class="ui-form__item" label="获得时间" name="createTime">
            <a-range-picker v-model:value="formState.createTime"/>
          </a-form-item>
        </a-row>

        <a-row>
          <a-col :span="18">
			  <span v-permission="['coupon_preferential_bindInfo_import']">
				  <exportReport type="userUnionCardExportStrategy" :searchData="searchData"></exportReport>
			  </span>
          </a-col>
          <a-col :span="6" style="text-align: right">
            <a-button
                style="margin-right: 10px"
                type="primary"
                html-type="submit"
            >搜索</a-button
            >
            <a-button @click="reset">重置</a-button>
          </a-col>
        </a-row>

      </a-form>

      <div style="margin-top: 20px;">
        <a-table :pagination="pagination" :columns="columns" :dataSource="list" rowKey="id"
                 :scroll="{ x: 2550 }">
          <template #bodyCell="{ column, record }">
            <template v-if="column.key === 'cardType'">
              <div v-if="record.cardType === 1">普通卡</div>
              <div v-if="record.cardType === 2">主题卡</div>
              <div v-if="record.cardType === 3">礼品卡</div>
            </template>
            <template v-if="column.key === 'status'">
              <div v-if="record.status === 0">待激活</div>
              <div v-if="record.status === 1">已激活</div>
              <div v-if="record.status === 2">转赠中</div>
              <div v-if="record.status === 3">已赠送</div>
			  <div v-if="record.status === 4">已退款</div>
            </template>
            <template v-if="column.key === 'userInfo'">
              <div>
                <div>用户昵称：{{ record.userNickname }}</div>
                <div>所属用户id：{{ record.userId }}</div>
                <div>用户手机号：{{ record.userPhone }}</div>
              </div>
            </template>
            <template v-if="column.key === 'fromType'">
              <div v-if="record.fromType === 1">用户自行购买</div>
              <div v-if="record.fromType === 2">转赠</div>
              <div v-if="record.fromType === 3">后台发放</div>
              <div v-if="record.fromType === 4">兑换券码</div>
			  <div v-if="record.fromType === 5">购买商品赠送</div>
			  <div v-if="record.fromType === 6">会员权益兑换</div>
			  <div v-if="record.fromType === 7">积分抽奖</div>
            </template>
            <template v-if="column.key === 'grantUserNickname'">
              {{ record.grantUserNickname || '-' }}
            </template>
            <template v-if="column.key === 'createTime'">
              {{ transDateTime(record.createTime) }}
            </template>
            <template v-if="column.key === 'isDisabled'">
              <a-tag v-if="!record.isDisabled" color="#87d068">启用</a-tag>
              <a-tag v-if="record.isDisabled" color="#f50">禁用</a-tag>
            </template>
            <template v-if="column.key === 'action'">
              <a-dropdown :trigger="['click', 'hover']">
                <a-button>操作 <Icon icon="DownOutlined"></Icon>
                </a-button>
                <template #overlay>
                  <a-menu>
                    <div v-permission="['coupon_preferential_bindInfo_open']" v-if="record.isDisabled === 1" @click="onDisabled(record)">
                      <a-menu-item>
                        启用
                      </a-menu-item>
                    </div>
                    <div v-permission="['coupon_preferential_bindInfo_close']" v-else @click="onDisabled(record)">
                      <a-menu-item>
                        禁用
                      </a-menu-item>
                    </div>
                    <div v-permission="['coupon_preferential_bindInfo_recharge']" @click="onRecharge(record)">
                      <a-menu-item>
                        充值
                      </a-menu-item>
                    </div>
                    <div v-permission="['coupon_preferential_bindInfo_rechargeRecord']" @click="onShow(record)">
                      <a-menu-item>
                        充值记录
                      </a-menu-item>
                    </div>
                    <!-- <div v-if="record.status === 0">
                      <a-menu-item>
                        售后
                      </a-menu-item>
                    </div> -->
                  </a-menu>
                </template>
              </a-dropdown>
            </template>
          </template>
        </a-table>
      </div>

      <a-modal title="充值" v-model:visible="isShowRecharge" style="width: 450px;" @ok="onRechargeOk">
        <a-spin :spinning="loading">
          <div class="detail">
            <a-spin :spinning="loading">
              <a-form style="margin-top: 30px;" ref="form" :model="modelRef" scrollToFirstError name="form" :labelCol="{span: 8}"
                      :wrapperCol="{span: 16}">

                <a-form-item name="balance" label="现有余额">
                  <span>{{ modelRef.balance }}</span>
                </a-form-item>

                <a-form-item label="充值金额" name="chargePrice" :rules="[{required: true, message: '必填项不允许为空'}]">
                  <a-input-number
                      min="0"
                      v-model:value="modelRef.chargePrice"
                      style="width: 150px; border-bottom-left-radius: 10px; border-top-left-radius: 10px"
                  ></a-input-number>
                  <span class="ui-min">元</span>
                </a-form-item>

                <a-form-item name="rechargedMoney" label="充值后金额">
                  <span>{{ +modelRef.balance + (modelRef.chargePrice || 0) }}</span>
                </a-form-item>
              </a-form>
            </a-spin>
          </div>
        </a-spin>
      </a-modal>
    </a-spin>

  </div>
  <temp v-if="showModal" :id="id" @back="onBack"></temp>
</template>

<script>
import temp from './temp.vue';
import exportReport from '@/components/exportReport/exportReport.vue';
import {
  Icon
} from '@/components/icon/icon.js';
import {
  chargeUserUnionCard,
  getUserUnionCardList,
  updateIsDisabledUserUnionCard
} from "@/service/modules/report.js";
import {updateCinema} from "@/service/modules/cinema.js";
export default {
  components: {
    Icon,
    temp,
	exportReport
  },
  data() {
    return {
      modelRef: {},
      searchData: {},
      isShowRecharge: false,
      showModal: false,
      isEdit: false,
      isSee: false,
      id: 0,
      loading: false,
      formState: {},
      columns: [{
        title: '一卡通名称',
        dataIndex: 'title',
      }, {
        title: '卡类型',
        key: 'cardType',
      }, {
        title: '一卡通卡号',
        dataIndex: 'cardNo',
      }, {
        title: '卡状态',
        key: 'status',
      }, {
        title: '卡余额',
        dataIndex: 'balance',
      }, {
        title: '所属用户信息', //（用户昵称、所属用户id、用户手机号）
        key: 'userInfo',
      }, {
        title: '获得方式', //（用户自行购买、转增、后台发放）
        key: 'fromType',
      }, {
        title: '转赠人/后台发放用户', //（注：获得方式为转赠或后台发放时才需要展示，其余为-）
        key: 'grantUserNickname',
      }, {
        title: '获得时间',
        key: 'createTime',
      }, {
        title: '状态',
        key: 'isDisabled',
      }, {
        title: '操作',
        key: 'action',
        fixed: 'right',
        width: 150
      }],
      list: [],
      pagination: {
        showSizeChanger: true,
        showQuickJumper: true,
        size: "middle",
        pageSizeOptions: ['10','20', '50', '100', '500'],
        showTotal: (total) => {
          return "共 " + total + " 条";
        },
        total: 0,
        current: 1,
        pageSize: 10,
        onChange: (page) => {
          this.pagination.current = page;
          this.getData();
        },
        onShowSizeChange: (page, pageSize) => {
          this.pagination.current = 1;
          this.pagination.pageSize = pageSize;
          this.getData();
        }
      },
    }
  },
  created() {
    this.getData();
  },
  methods: {
    reset() {
      this.$refs.form.resetFields();
      this.onSearch();
    },
    onSearch() {
      this.pagination.current = 1;
      this.searchData = JSON.parse(JSON.stringify(this.formState));
      this.getData();
    },
    onShow(item) {
      this.id = item.id;
      this.isEdit = false;
      this.isSee = true;
      this.showModal = true;
    },
    onBack() {
      this.showModal = false;
    },
    onRecharge(item) {
      this.id = item.id;
      this.isShowRecharge = true;
      this.modelRef.balance = item.balance;
      this.modelRef.chargePrice = null;
    },
    async getData() {
      if(this.formState.createTime && this.formState.createTime.length === 2) {
        this.searchData.createStartTime = this.moment(this.formState.createTime[0].startOf('day')).unix();
        this.searchData.createEndTime = this.moment(this.formState.createTime[1].endOf('day')).unix();
      }
      this.loading = true;
      try {
        let ret = await getUserUnionCardList({
          page: this.pagination.current,
          pageSize: this.pagination.pageSize,
          ...this.searchData
        })
        this.loading = false;
        if(ret.code === 200) {
          this.list = ret.data.list;
          this.list.forEach(item => {
            if(item.status != 1) {
              item.balance = item.parPrice;
            }
          });
          this.pagination.total = ret.data.totalCount;
        }
      } catch(e) {
        this.loading = false;
      }
    },
    onDisabled(item) {
      this.$confirm({
        title: '提示',
        content: '确定' + (item.isDisabled ? '启用' : '禁用') + '吗？',
        onOk: async () => {
          this.loading = true;
          try {
            let ret = await updateIsDisabledUserUnionCard({
              id: item.id,
              isDisabled: item.isDisabled ? 0 : 1
            });
            this.loading = false;
            if (ret.code === 200) {
              this.$message.success(item.isDisabled ? '启用' : '禁用' + '成功');
              this.getData();
            }
          } catch(e) {
            this.loading = false;
          }
        }
      })
    },
    onRechargeOk() {
      this.$confirm({
		  title: '提示',
		  content: '确定充值吗？',
		  onOk: async ()=> {
			this.loading = true;
			try {
			  let ret = await chargeUserUnionCard({
			    id: this.id,
			    chargePrice: this.modelRef.chargePrice
			  })
			  this.loading = false;
			  if(ret.code === 200) {
			    this.$message.success('操作成功');
			    this.getData();
			    this.isShowRecharge = false;
			  }
			} catch(e) {
			  this.loading = false;
			}
		  }
	  })
    },
  }
}
</script>

<style scoped>
.ui-form__item {
  margin-right: 30px;
}

.ui-min {
  display: inline-block;
  width: 40px;
  height: 31px;
  text-align: center;
  line-height: 31px;
  background-color: #d7d7d7;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
}
</style>
